var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitResponse.apply(null, arguments)}}},[_c('p',{staticClass:"mb-3"},[_vm._v(" Consider the information shown in the blue box below. "),_c('stemble-latex',{attrs:{"content":"$E^\\circ$"}}),_vm._v(" for the silver half-reaction is greater than "),_c('stemble-latex',{attrs:{"content":"$E^\\circ$"}}),_vm._v(" for the copper half-reaction. ")],1),_c('p',{staticClass:"mb-2"},[_vm._v(" Please complete the Process Table below by specifying the process that should replace BLANK #1 and BLANK #2. ")]),_c('p',{staticClass:"mb-2"},[_c('v-img',{staticStyle:{"max-width":"400px"},attrs:{"src":"/img/assignments/ubcFindingAValueForE.png"}})],1),_c('p',{staticClass:"mb-2"},[_vm._v(" BLANK #1 "),_c('v-select',{staticClass:"ml-2",staticStyle:{"display":"inline-block","max-width":"300px"},attrs:{"items":_vm.items,"item-text":"text","item-value":"value","label":"Select option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.blank1),callback:function ($$v) {_vm.$set(_vm.inputs, "blank1", $$v)},expression:"inputs.blank1"}})],1),_c('p',{staticClass:"mb-3"},[_vm._v(" I think that because: "),_c('s-textarea',{attrs:{"outlined":""},model:{value:(_vm.inputs.justification1),callback:function ($$v) {_vm.$set(_vm.inputs, "justification1", $$v)},expression:"inputs.justification1"}})],1),_c('p',{staticClass:"mb-2"},[_vm._v(" BLANK #2 "),_c('v-select',{staticClass:"ml-2",staticStyle:{"display":"inline-block","max-width":"300px"},attrs:{"items":_vm.items,"item-text":"text","item-value":"value","label":"Select option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.blank2),callback:function ($$v) {_vm.$set(_vm.inputs, "blank2", $$v)},expression:"inputs.blank2"}})],1),_c('p',{staticClass:"mb-3"},[_vm._v(" I think that because: "),_c('s-textarea',{attrs:{"outlined":""},model:{value:(_vm.inputs.justification2),callback:function ($$v) {_vm.$set(_vm.inputs, "justification2", $$v)},expression:"inputs.justification2"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }