<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Consider the information shown in the blue box below.
        <stemble-latex content="$E^\circ$" />
        for the silver half-reaction is greater than
        <stemble-latex content="$E^\circ$" />
        for the copper half-reaction.
      </p>
      <p class="mb-2">
        Please complete the Process Table below by specifying the process that should replace BLANK
        #1 and BLANK #2.
      </p>
      <p class="mb-2">
        <v-img style="max-width: 400px" src="/img/assignments/ubcFindingAValueForE.png" />
      </p>

      <p class="mb-2">
        BLANK #1
        <v-select
          v-model="inputs.blank1"
          style="display: inline-block; max-width: 300px"
          class="ml-2"
          :items="items"
          item-text="text"
          item-value="value"
          label="Select option:"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-3">
        I think that because:
        <s-textarea v-model="inputs.justification1" outlined />
      </p>

      <p class="mb-2">
        BLANK #2
        <v-select
          v-model="inputs.blank2"
          style="display: inline-block; max-width: 300px"
          class="ml-2"
          :items="items"
          item-text="text"
          item-value="value"
          label="Select option:"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-3">
        I think that because:
        <s-textarea v-model="inputs.justification2" outlined />
      </p>
    </v-form>
  </div>
</template>

<script>
import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '../StembleLatex.vue';

export default {
  name: 'ChemUBC154ElectrochemPLQ3',
  components: {
    StembleLatex,
    STextarea,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        blank1: null,
        justification1: null,
        blank2: null,
        justification2: null,
      },
      items: [
        {text: 'Reduction of Copper', value: 'copperReduc'},
        {text: 'Reduction of Silver', value: 'silverReduc'},
        {text: 'Oxidation of Copper', value: 'copperOxi'},
        {text: 'Oxidation of Silver', value: 'silverOxi'},
      ],
    };
  },
};
</script>
<style scoped></style>
